<template>
  <!--  查看发票-->
  <div class="chakan">
    <div class="chakan1">
      <iframe :src="pdfDownloadUrl" class="iframe"/>
    </div>
    <!-- <div class="chakan2" @click="down">手机浏览器下载</div> -->
    <div class="chakan3" v-clipboard:copy="pdfDownloadUrl" v-clipboard:success="onCopy">复制下载链接</div>
  </div>
</template>
<script>
import {Toast} from "vant"

export default {
  data() {
    return {
      invoice: {},
      pdfDownloadUrl: "",
      pdfUrl: ""
    }
  },
  watch: {
    "invoice": function (o) {
      this.pdfDownloadUrl = o.pictureneturl;
    }
  },
  methods: {
    down() {
      this.pdfDownloadUrl=this.invoice.pictureneturl
      // window.location.href = this.invoice.pictureneturl
    },
    onCopy() {
      Toast({
        message: '下载地址拷贝成功，请在浏览器中打开'
      })
    },
  },
  created() {
    this.invoice = JSON.parse(window.sessionStorage.getItem('tabList'));
    // this.invoice = {
    //   "patientId": "0010001597",
    //   "visitId": 42,
    //   "name": "王红星",
    //   "deptName": "心血管内科祁华琪",
    //   "invoiceNo": "0005902418",
    //   "chrgDate": "2020-12-22 11:49:26",
    //   "totalCosts": 19.82,
    //   "pictureneturl": "http://fs.hncz.gov.cn:8094/EivcCheckH5?payer=%E7%8E%8B%E7%BA%A2%E6%98%9F&ivccode=41060120&ivcno=0005902418&ckno=nqj001"
    //   ,
    //   "pictureurl": "http://192.168.1.76:8321/down?payer=%E7%8E%8B%E7%BA%A2%E6%98%9F&ivccode=41060120&ivcno=0005902418&ckno=nqj001"
    // }
    // thi  s.info.pictureneturl = pdf.createLoadingTask(this.info.pictureneturl)
    this.pdfUrl = this.invoice.pictureurl + "?inoviceNo=" + this.invoice.invoiceNo
    // console.log(this.invoice);
  },
}
</script>
<style scoped>
.body {
  background-color: #f8f9f9;
}

.iframe {
  transform-origin: 0 top 0
}

.chakan {
  width: 100%;
  height: 100%;
}

.chakan1 {
  width: 10rem;
  margin: auto;
}

.chakan1 img {
  width: 10rem;
}

.chakan2 {
  width: 9rem;
  height: 40px;
  line-height: 40px;
  background-color: #00D48B;
  color: #fff;
  font-size: 16px;
  margin: auto;
  margin-top: 50px;
  border-radius: 5px;
}

.chakan3 {
  width: 9rem;
  height: 40px;
  line-height: 40px;
  border: 1px solid #00D48B;
  color: #8D8D8D;
  font-size: 16px;
  margin: auto;
  margin-top: 20px;
  border-radius: 5px;
}

.iframe {
  width: 100%;
  height: 500px;
  border: none;
}
</style>